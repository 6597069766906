import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { FaRegTimesCircle } from "react-icons/fa";
import axiosInstance from "../../helpers/axios/axiosInstance";

const AllImages = ({ imageItem, setImgModal, setUploadedImg1, setImage }) => {
  window.scrollTo(0, 0);
  const [images, setImages] = useState([]);
  const [page, setPage] = useState(1);

  const handleClick = async (link) => {
    if (imageItem === 1) {
      setUploadedImg1(link);
      setImage(link);
    }

    if (imageItem === 2) {
      try {
        await navigator.clipboard.writeText(link);
        toast.success("Link copied to clipboard!");
      } catch (err) {
        toast.error("Failed to copy the link");
      }
    }
    setImgModal(false);
  };

  useEffect(() => {
    axiosInstance
      .get(`${process.env.REACT_APP_URL}/uploaded-images?page=${page}`)
      .then((response) => {
        setImages(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    // const fetchImages = async () => {
    //   try {
    //     const response = await axios.get(
    //       `${process.env.REACT_APP_URL}/uploaded-images?page=${page}`,
    //       {
    //         headers: {
    //           Authorization: `Bearer ${cookie}`,
    //         },
    //       }
    //     );
    //     setImages(response.data);
    //   } catch (error) {
    //     console.error("Error fetching images:", error);
    //   }
    // };
    // fetchImages();
  }, [page]);

  const handleCloseModal = () => {
    setImgModal(false);
  };

  if (!images?.length) {
    return (
      <h2 className="text-base font-bold flex justify-center items-center text-center p-2">
        Loading...
      </h2>
    );
  }

  return (
    <div className="w-full h-screen bg-gray-200 fixed z-50 top-0 left-0 flex justify-center items-center">
      <div className="md:w-[80%] relative">
        <span
          onClick={() => handleCloseModal()}
          className="bg-red-500 p-2 rounded-full text-xl text-white absolute top-0 right-2 cursor-pointer"
        >
          <FaRegTimesCircle />
        </span>
        <div className="w-full mx-auto p-2 md:p-4 bg-white h-[90vh] md:h-[80vh] overflow-y-scroll">
          <div>
            <h3 className="text-lg text-center font-semibold p-2">
              Select an Image
            </h3>
            <hr />
          </div>
          <p className="my-2 mx-4 font-semibold">Page {page}</p>
          <div className="p-2 flex gap-2 md:gap-4 flex-wrap justify-center bg-gray-100">
            {images?.map((image, i) => (
              <div key={i} className="flex gap-2 md:gap-4">
                <div
                  onClick={() => handleClick(image?.img)}
                  className="w-[120px] md:w-[150px] cursor-pointer"
                >
                  <img
                    className="rounded-md aspect-[4/3] object-cover"
                    src={image?.img}
                    alt="news-img"
                  />
                  {imageItem === 2 && (
                    <p className="text-center bg-blue-400 text-white p-1 rounded-sm cursor-pointer text-[10px]">
                      Copy Link
                    </p>
                  )}
                </div>
                {image?.img2 && (
                  <div
                    onClick={() => handleClick(image?.img2)}
                    className="w-[120px] md:w-[150px] cursor-pointer"
                  >
                    <img
                      className="rounded-md aspect-[4/3] object-cover"
                      src={image?.img2}
                      alt="news-img"
                    />
                    {imageItem === 2 && (
                      <p className="text-center bg-blue-400 text-white p-1 rounded-sm cursor-pointer text-[10px]">
                        Copy Link
                      </p>
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className="flex justify-center my-5 gap-4">
            <button
              disabled={page < 2}
              onClick={() => setPage(page - 1)}
              className={`py-1 px-5 text-sm bg-[#3B82F6] text-white rounded-sm ${
                page < 2 && "opacity-50 cursor-not-allowed"
              }`}
            >
              Prev
            </button>
            <button
              disabled={images?.length < 40}
              onClick={() => setPage(page + 1)}
              className={`py-1 px-5 text-sm bg-[#3B82F6] text-white rounded-sm ${
                images?.length < 40 && "opacity-50 cursor-not-allowed"
              }`}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllImages;
