import React, { useContext, useEffect } from "react";
import { FaAngleLeft, FaAngleRight, FaArrowUp } from "react-icons/fa";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../Context/AuthProvider";
import {
  getUserInfo,
  isUserActive,
  logout,
} from "../../helpers/auth/authHelpers";
import Footer from "../Shared/Footer";
import Navbar from "../Shared/Navbar";

const Dashboard = () => {
  window.scrollTo(0, 0);
  const { open, setOpen, value, setValue } = useContext(AuthContext);
  const navigate = useNavigate();
  const user = getUserInfo();
  const path = useLocation()?.pathname;

  const handleClick = (e) => {
    setOpen(false);
    setValue(e.target.innerText);
    navigate("/dashboard/newsList");
  };

  // Log out function
  const handleLogOut = () => {
    logout();
  };

  const handleBackToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    isUserActive();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);

  return (
    <>
      <Navbar />
      <div className="relative min-h-screen -ml-3 -mt-4">
        <div
          onClick={handleBackToTop}
          className="cursor-pointer p-4 bg-red-500 rounded-full z-50 text-2xl text-white fixed top-[90%] right-2 duration-1000 ease-in-out"
        >
          <FaArrowUp />
        </div>
        <div className="mt-10">
          <Outlet />
        </div>
        {/* Side bar */}
        <div>
          <p
            className="absolute top-[82px] rounded-r-md left-3 bg-gray-800 text-white p-2 cursor-pointer md:hidden"
            onClick={() => setOpen(true)}
          >
            <FaAngleRight className="w-7 h-7" />
          </p>
          <div
            className={`top-[82px] ml-2 md:top-0 w-[200px] left-0 duration-1000 ease-in-out min-h-[100%] bg-gray-700 absolute transform ${
              open
                ? "-translate-x-[0%] md:translate-x-0"
                : "-translate-x-[200%] md:translate-x-0"
            } `}
          >
            <p
              className="absolute top-0 -right-[43px] bg-red-500 p-2 rounded-r-md cursor-pointer text-white md:hidden"
              onClick={() => setOpen(false)}
            >
              <FaAngleLeft className="w-7 h-7" />
            </p>
            <div className="border-b-2">
              <p className="text-2xl text-center my-2 text-white font-bold">
                <Link onClick={() => setOpen(false)} to="/dashboard">
                  Dashboard
                </Link>
              </p>
            </div>
            <div className="mx-4 mt-8 flex flex-col gap-4 ">
              <Link to="/dashboard/addNews">
                <button
                  onClick={() => setOpen(false)}
                  className="px-4 py-1 w-full bg-blue-500 text-white rounded-sm mb-2"
                >
                  New Post
                </button>
              </Link>
              <p
                onClick={(e) => handleClick(e)}
                className={`cursor-pointer text-white text-lg ${
                  value === "সব খবর" && "bg-gray-500 px-1 rounded-sm"
                }`}
              >
                সব খবর
              </p>
              <p
                onClick={(e) => handleClick(e)}
                className={`cursor-pointer text-white text-lg ${
                  value === "কোম্পানীগঞ্জ" && "bg-gray-500 px-1 rounded-sm"
                }`}
              >
                কোম্পানীগঞ্জ
              </p>
              <p
                onClick={(e) => handleClick(e)}
                className={`cursor-pointer text-white text-lg ${
                  value === "সিলেট" && "bg-gray-500 px-1 rounded-sm"
                }`}
              >
                সিলেট
              </p>
              <p
                onClick={(e) => handleClick(e)}
                className={`cursor-pointer text-white text-lg ${
                  value === "জাতীয়" && "bg-gray-500 px-1 rounded-sm"
                }`}
              >
                জাতীয়
              </p>
              <p
                onClick={(e) => handleClick(e)}
                className={`cursor-pointer text-white text-lg ${
                  value === "আন্তর্জাতিক" && "bg-gray-500 px-1 rounded-sm"
                }`}
              >
                আন্তর্জাতিক
              </p>
              <p
                onClick={(e) => handleClick(e)}
                className={`cursor-pointer text-white text-lg ${
                  value === "খেলা" && "bg-gray-500 px-1 rounded-sm"
                }`}
              >
                খেলা
              </p>
              <p
                onClick={(e) => handleClick(e)}
                className={`cursor-pointer text-white text-lg ${
                  value === "স্বাস্থ্য" && "bg-gray-500 px-1 rounded-sm"
                }`}
              >
                স্বাস্থ্য
              </p>
              <p
                onClick={(e) => handleClick(e)}
                className={`cursor-pointer text-white text-lg ${
                  value === "বাণিজ্য" && "bg-gray-500 px-1 rounded-sm"
                }`}
              >
                বাণিজ্য
              </p>
              <p
                onClick={(e) => handleClick(e)}
                className={`cursor-pointer text-white text-lg ${
                  value === "বিনোদন" && "bg-gray-500 px-1 rounded-sm"
                }`}
              >
                বিনোদন
              </p>
              <p
                onClick={(e) => handleClick(e)}
                className={`cursor-pointer text-white text-lg ${
                  value === "সাহিত্য" && "bg-gray-500 px-1 rounded-sm"
                }`}
              >
                সাহিত্য
              </p>
              <p
                onClick={(e) => handleClick(e)}
                className={`cursor-pointer text-white text-lg ${
                  value === "শিক্ষা" && "bg-gray-500 px-1 rounded-sm"
                }`}
              >
                শিক্ষা
              </p>
              <p
                onClick={(e) => handleClick(e)}
                className={`cursor-pointer text-white text-lg ${
                  value === "প্রযুক্তি" && "bg-gray-500 px-1 rounded-sm"
                }`}
              >
                প্রযুক্তি
              </p>
              <p
                onClick={(e) => handleClick(e)}
                className={`cursor-pointer text-white text-lg ${
                  value === "ধর্ম" && "bg-gray-500 px-1 rounded-sm"
                }`}
              >
                ধর্ম
              </p>
              <p
                onClick={(e) => handleClick(e)}
                className={`cursor-pointer text-white text-lg ${
                  value === "ভ্রমণ" && "bg-gray-500 px-1 rounded-sm"
                }`}
              >
                ভ্রমণ
              </p>
              <p
                onClick={(e) => handleClick(e)}
                className={`cursor-pointer text-white text-lg ${
                  value === "মতামত" && "bg-gray-500 px-1 rounded-sm"
                }`}
              >
                মতামত
              </p>
              {user?.role === "admin" && (
                <Link
                  to="/dashboard/users"
                  className={`cursor-pointer text-white text-lg`}
                >
                  All Users
                </Link>
              )}
              {user?.email && (
                <button
                  className="cursor-pointer mt-5 mb-2 bg-red-500 p-1 rounded-md text-white font-bold"
                  onClick={handleLogOut}
                >
                  Log Out
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="mt-[70px] md:mt-0">
        <Footer />
      </div>
    </>
  );
};

export default Dashboard;
