import { jwtDecode } from "jwt-decode";
import axiosInstance from "../axios/axiosInstance";

export const getUserInfo = () => {
  const token = localStorage.getItem("accessToken");
  if (token) {
    const decodedData = jwtDecode(token);
    return {
      ...decodedData,
      role: decodedData?.role?.toLowerCase(),
    };
  } else {
    return null;
  }
};

export const logout = async () => {
  fetch(`${process.env.REACT_APP_URL}/logout`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    credentials: "include",
  })
    .then((res) => res.json())
    .then((data) => {
      localStorage.removeItem("accessToken");
      window.location.reload();
    });
};

export const isUserActive = () => {
  axiosInstance.get(`${process.env.REACT_APP_URL}/my-profile`).then((res) => {
    if (res?.data?.status && getUserInfo()?.status) {
      if (res?.data?.status !== getUserInfo()?.status) {
        logout();
      }
    }
  });
};
