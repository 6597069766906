import React, { useRef, useState } from "react";
import { Toaster, toast } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import NewsUpdate from "../../Component/NewsUpdate";
import axiosInstance from "../../helpers/axios/axiosInstance";
import { uploadImages } from "../AddNews/components/uploadImages";
import AllImages from "../Dashboard/AllImages";
import TextEditor from "./../AddNews/components/TextEditor";
import { isUserActive } from "../../helpers/auth/authHelpers";

const EditPost = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dData = location?.state;
  const [checkLead, setCheckLead] = useState(dData?.leadNews);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [videoUrl, setVideoUrl] = useState(dData?.video);
  const [imgModal, setImgModal] = useState(false);
  const [uploadImg1, setUploadedImg1] = useState("");
  const [browsedFiles, setBrowsedFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [description, setDescription] = useState(dData?.des);
  const [imageItem, setImageItem] = useState(0);
  const fileInputRef = useRef(null);
  const formRef = useRef(null);
  const [formData, setFormData] = useState({
    title: dData?.title,
    subTitle: dData?.subTitle,
    leadNews: checkLead,
    img: dData?.img,
    category: dData?.category,
    postBy: dData?.postBy,
    des: dData?.des,
  });

  const updatedDb = (updatedData) => {
    isUserActive();
    axiosInstance
      .put(
        `${process.env.REACT_APP_URL}/updatedNews/${dData?._id}`,
        updatedData
      )
      .then((res) => {
        setLoading(false);
        if (res?.status === 200) {
          toast.success("Post Updated");
          setImage("");
          setUploadedImg1("");
          navigate("/dashboard/newsList");
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    NewsUpdate({
      updatedDb,
      image,
      uploadImg1,
      formData,
      videoUrl,
      checkLead,
      description,
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormData({ ...formData, [name]: value });
  };

  return (
    <div>
      <Toaster />
      <div className="container max-w-full mx-auto md:px-6">
        <div className="max-w-lg mx-auto md:px-6 mb-10">
          <div className="relative flex flex-wrap">
            <div className="w-full relative my-20 md:my-0 ml-5 md:ml-0 mr-4 md:mr-0">
              <div className="md:mt-6">
                <h2 className="text-center text-3xl font-semibold text-black mt-5 md:mt-0">
                  Edit Post
                </h2>
                <form
                  ref={formRef}
                  onSubmit={handleSubmit}
                  className="mt-8"
                  x-data="{password: '',password_confirm: ''}"
                >
                  <div className="mx-auto max-w-lg ">
                    <div className="py-1">
                      <span className="px-1 text-sm text-gray-600">Title</span>
                      <textarea
                        rows="2"
                        defaultValue={dData?.title}
                        placeholder="Title"
                        required
                        onChange={handleChange}
                        name="title"
                        type="text"
                        className="text-md block px-3 py-2 rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-600 shadow-md focus:placeholder-gray-500 focus:bg-white focus:border-gray-600 focus:outline-none"
                      />
                    </div>
                    <div className="py-1">
                      <span className="px-1 text-sm text-gray-600">
                        Sub Title
                      </span>
                      <textarea
                        rows="2"
                        defaultValue={dData?.subTitle}
                        placeholder="Sub Title"
                        onChange={handleChange}
                        name="subTitle"
                        type="text"
                        className="text-md block px-3 py-2 rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-600 shadow-md focus:placeholder-gray-500 focus:bg-white focus:border-gray-600 focus:outline-none"
                      />
                    </div>

                    <div className="py-1">
                      <span className="px-1 text-sm text-gray-600">
                        Author name
                      </span>
                      <input
                        defaultValue={dData?.postBy}
                        placeholder="Author Name"
                        onChange={handleChange}
                        name="postBy"
                        type="text"
                        className="text-md block px-3 py-2 rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-600 shadow-md focus:placeholder-gray-500 focus:bg-white focus:border-gray-600 focus:outline-none"
                      />
                    </div>

                    <div className="py-1 mt-1">
                      <p className="px-1 text-sm text-gray-600">Banner Image</p>

                      <input
                        id="directImg"
                        onChange={() => setUploadedImg1("")}
                        name="directImg"
                        type="checkbox"
                        className="my-2"
                        checked={!uploadImg1}
                      />
                      <label
                        className="px-1 text-sm text-black font-semibold ml-2 mr-5"
                        htmlFor="directImg"
                      >
                        Direct Image
                      </label>
                      <input
                        id="imageLink"
                        onChange={() => {
                          setImgModal(true);
                          setImageItem(1);
                        }}
                        name="imageLink"
                        type="checkbox"
                        className="my-2"
                        checked={uploadImg1}
                      />
                      <label
                        className="px-1 text-sm text-black font-semibold ml-2"
                        htmlFor="imageLink"
                      >
                        Uploaded Images
                      </label>
                    </div>

                    {!uploadImg1 ? (
                      <div className="py-1 flex gap-2">
                        <input
                          placeholder=""
                          onChange={(e) => setImage(e.target.files[0])}
                          name="img"
                          type="file"
                          className="text-md block px-3 py-2 w-full"
                        />
                        {!image?.name && (
                          <div className="py-1">
                            <img
                              className="w-[100px] aspect-[5/3] rounded-md"
                              src={dData?.img}
                              alt="img2"
                            />
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="py-1">
                        <img
                          className="w-[100px] aspect-[5/3] rounded-md"
                          src={uploadImg1}
                          alt="img2"
                        />
                      </div>
                    )}

                    <div className="py-1">
                      <span className="px-1 text-sm text-gray-600">
                        Video Url
                      </span>
                      <input
                        placeholder="https://..."
                        onChange={(e) => setVideoUrl(e.target.value)}
                        name="videoUrl"
                        type="text"
                        defaultValue={videoUrl}
                        className="text-md block px-3 py-2 rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-600 shadow-md focus:placeholder-gray-500 focus:bg-white focus:border-gray-600 focus:outline-none"
                      />
                    </div>
                    <div className="py-1">
                      <p className="px-1 text-sm text-gray-600">Category</p>
                      <select
                        required
                        name="category"
                        id="cars border"
                        className="text-md block px-3 py-2 rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-600 shadow-md focus:placeholder-gray-500 focus:bg-white focus:border-gray-600 focus:outline-none"
                        value={formData.category}
                        onChange={handleChange}
                      >
                        <option value="কোম্পানীগঞ্জ">কোম্পানীগঞ্জ </option>
                        <option value="সিলেট">সিলেট</option>
                        <option value="জাতীয়">জাতীয়</option>
                        <option value="আন্তর্জাতিক">আন্তর্জাতিক</option>
                        <option value="খেলা">খেলা</option>
                        <option value="স্বাস্থ্য">স্বাস্থ্য</option>
                        <option value="বাণিজ্য">বাণিজ্য</option>
                        <option value="বিনোদন">বিনোদন</option>
                        <option value="সাহিত্য">সাহিত্য</option>
                        <option value="শিক্ষা">শিক্ষা</option>
                        <option value="প্রযুক্তি">প্রযুক্তি</option>
                        <option value="ধর্ম">ধর্ম</option>
                        <option value="ভ্রমণ">ভ্রমণ</option>
                        <option value="মতামত">মতামত</option>
                      </select>
                    </div>

                    <div className="py-1">
                      <input
                        id="leadnews"
                        onChange={() => setCheckLead(!checkLead)}
                        name="leadNews"
                        type="checkbox"
                        checked={checkLead}
                        className="my-2"
                      />
                      <label
                        className="px-1 text-sm text-gray-600 ml-2"
                        htmlFor="leadnews"
                      >
                        Lead News
                      </label>
                    </div>

                    <div className="my-2 flex justify-between items-center">
                      <div className=" text-white w-[70%]">
                        <p className="p-2 bg-gray-500 hover:bg-gray-600 rounded-md text-[10px]">
                          Upload Multiple files
                          <input
                            multiple
                            type="file"
                            disabled={uploading}
                            onChange={(e) => setBrowsedFiles(e.target.files)}
                            ref={fileInputRef}
                            className="block"
                          />
                        </p>
                        <p
                          onClick={() =>
                            uploadImages(
                              browsedFiles,
                              setUploading,
                              setBrowsedFiles,
                              fileInputRef
                            )
                          }
                          disabled={uploading || !browsedFiles?.length}
                          className={`p-2 text-center bg-green-500 hover:bg-green-600 text-white rounded-sm text-[10px] mt-1 ${
                            uploading || !browsedFiles?.length
                              ? "opacity-70 cursor-not-allowed"
                              : "cursor-pointer"
                          }`}
                        >
                          {uploading ? "Uploading..." : "Upload"}
                        </p>
                      </div>
                      <div
                        onClick={() => {
                          setImgModal(true);
                          setImageItem(2);
                        }}
                        className="p-2 text-center cursor-pointer bg-gray-500 hover:bg-gray-600 text-white rounded-md text-[10px]"
                      >
                        Browse Images
                      </div>
                    </div>

                    <div className="py-1">
                      <label
                        id=""
                        className="px-1 text-sm text-gray-600 ml-2 flex justify-between"
                      >
                        <span>Description</span>
                      </label>

                      <TextEditor
                        description={description}
                        setDescription={setDescription}
                      />
                    </div>

                    <button
                      disabled={loading}
                      type="submit"
                      className="mt-3 text-lg font-semibold bg-gray-800 w-full text-white rounded-lg px-6 py-3 block shadow-xl hover:text-white hover:bg-black"
                    >
                      {loading ? (
                        <p className="w-7 h-7 border-4 border-dashed rounded-full animate-spin mx-auto"></p>
                      ) : (
                        "Update"
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {imgModal && (
        <AllImages
          imageItem={imageItem}
          setImgModal={setImgModal}
          setUploadedImg1={setUploadedImg1}
          setImage={setImage}
        />
      )}
    </div>
  );
};

export default EditPost;
