import axios from "axios";

const NewsUpdate = async ({
  updatedDb,
  image,
  uploadImg1,
  formData,
  videoUrl,
  checkLead,
  description,
}) => {
  const { title, category, subTitle, postBy, img } = formData;

  let url = uploadImg1 || img;

  try {
    if (image?.name) {
      const img1 = new FormData();
      img1.append("file", image);
      img1.append(
        "upload_preset",
        process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET
      );

      const upload = await axios.post(
        `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload`,
        img1
      );
      url = upload?.data?.secure_url;
    }
    const newsData = {
      title,
      subTitle,
      img: url,
      video: videoUrl,
      category,
      leadNews: checkLead,
      postBy,
      des: description,
    };
    updatedDb(newsData);
    return;
  } catch (err) {
    console.log(err);
  }
};

export default NewsUpdate;
