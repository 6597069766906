import axios from "axios";
import toast from "react-hot-toast";
import axiosInstance from "../../../helpers/axios/axiosInstance";

export const uploadImages = async (
  browsedFiles,
  setUploading,
  setBrowsedFiles,
  fileInputRef
) => {
  setUploading(true);
  const results = [];

  if (browsedFiles?.length > 0) {
    for (let i = 0; i < browsedFiles.length; i++) {
      const image = new FormData();
      image.append("file", browsedFiles[i]);
      image.append(
        "upload_preset",
        process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET
      );

      try {
        const response = await axios.post(
          `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload`,
          image
        );

        if (response?.data?.secure_url) {
          results.push({ img: response?.data?.secure_url });
        }
      } catch (error) {
        toast.error(error.message);
      }
    }
  }

  if (results.length > 0) {
    axiosInstance
      .post(`${process.env.REACT_APP_URL}/upload-images`, results)
      .then((res) => {
        if (res?.data?.acknowledged) {
          toast.success("Image upload success");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  setUploading(false);
  setBrowsedFiles([]);
  if (fileInputRef.current) {
    fileInputRef.current.value = "";
  }
};
