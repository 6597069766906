import React, { useContext, useEffect, useState } from "react";
import DeleteModal from "../../Component/DeleteModal";
import EditModal from "../../Component/EditModal";
import { AuthContext } from "../../Context/AuthProvider";
import axiosInstance from "../../helpers/axios/axiosInstance";
import DashboardNewsForLg from "./DashboardNewsForLg";
import DashboardNewsForSm from "./DashboardNewsForSm";

const NewsList = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [hiddenCategory, setHiddenCategory] = useState(true);
  const [editModal, setEditModal] = useState(false);
  const [editData, setEditData] = useState({});
  const [deleteId, setDeleteId] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const { loading, setLoading, value } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    setLoading(true);
    const categoryData = async () => {
      try {
        const res = await axiosInstance.get(
          `${process.env.REACT_APP_URL}/${
            value === "সব খবর" ? "allNews" : "category"
          }`,
          {
            params: { number: limit, category: value, pathName: "/dashboard" },
          }
        );

        setData(res.data);
        setLoading(false);
        setHiddenCategory(true);
      } catch (err) {
        console.log(err);
      }
    };
    categoryData();
  }, [value, setLoading, isDeleted, setIsDeleted, limit]);

  // Search News
  const handleSearch = (e) => {
    e.preventDefault();
    axiosInstance
      .get(`${process.env.REACT_APP_URL}/search?title=${searchTerm}`, {
        params: { pathName: "/dashboard" },
      })
      .then((response) => {
        setData(response.data);
        setHiddenCategory(false);
        setSearchTerm("");
      })
      .catch((error) => {
        console.error("Failed to fetch search results", error);
      });
  };

  //See more data
  const handleLoad = () => {
    setLimit(limit + 8);
  };

  return (
    <>
      <div>
        <DeleteModal
          isDeleted={isDeleted}
          setIsDeleted={setIsDeleted}
          setDeleteModal={setDeleteModal}
          deleteModal={deleteModal}
          deleteId={deleteId}
          setDeleteId={setDeleteId}
        />
        <EditModal
          editModal={editModal}
          setEditModal={setEditModal}
          editData={editData}
          setEditData={setEditData}
        />
      </div>
      <div className="md:w-[80%] md:ml-[200px] min-h-screen">
        <div className="flex justify-end pt-2">
          <form
            onSubmit={handleSearch}
            className="border rounded overflow-hidden flex mt-[74px] md:mt-0"
          >
            <input
              type="text"
              className="px-4 py-2"
              required
              value={searchTerm}
              placeholder="Search By Title..."
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <button
              type="submit"
              className="flex items-center justify-center px-4 border-l"
            >
              <svg
                className="h-4 w-4 text-grey-dark"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <path d="M16.32 14.9l5.39 5.4a1 1 0 0 1-1.42 1.4l-5.38-5.38a8 8 0 1 1 1.41-1.41zM10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z" />
              </svg>
            </button>
          </form>
        </div>
        {loading ? (
          <div className="text-md mt-[70px] md:mt-0 font-bold text-center">
            Loading...
          </div>
        ) : (
          <div className="mx-2">
            <h2 className="text-2xl font-bold text-center my-3 pt-4 border-b border-gray-500 mx-2">
              {hiddenCategory
                ? value === "সব খবর"
                  ? "সব খবর"
                  : data[0]?.category
                : "সার্চ রেজাল্ট"}
            </h2>

            <div className="md:hidden">
              <DashboardNewsForSm
                handleLoad={handleLoad}
                data={data}
                setDeleteId={setDeleteId}
                setDeleteModal={setDeleteModal}
                setEditModal={setEditModal}
                editData={editData}
                setEditData={setEditData}
                setLoading={setLoading}
              />
            </div>
            <div className="hidden md:block">
              <DashboardNewsForLg
                handleLoad={handleLoad}
                data={data}
                setDeleteId={setDeleteId}
                setDeleteModal={setDeleteModal}
                setEditModal={setEditModal}
                editData={editData}
                setEditData={setEditData}
                setLoading={setLoading}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default NewsList;
