import React from "react";
import { Navigate } from "react-router-dom";
import { getUserInfo } from "../helpers/auth/authHelpers";

const AdminRoutes = ({ children }) => {
  const user = getUserInfo();

  if (user?.role === "admin") {
    return children;
  }

  return <Navigate to="/"></Navigate>;
};

export default AdminRoutes;
