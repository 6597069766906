import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { FaClock } from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { convertToBengaliNumber } from "../../Component/GetDate";
import { getTimeAgo } from "../../Component/GetTimeAgo";
import SorboseshNewsSwiper from "../../Component/SorboseshNewsSwiper";
import VideoPlayer from "../../Component/VideoPlayer";
import { getBanCategory } from "../../Component/getCategory";
import { AuthContext } from "../../Context/AuthProvider";
import axiosInstance from "../../helpers/axios/axiosInstance";

const NewsCategory = () => {
  const [limit, setLimit] = useState(7);
  const { data, loading, setLoading, scrollY, setScrollY, view } =
    useContext(AuthContext);
  const [news, setNews] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const path = location.pathname?.split("/")[1];

  //See more data
  const handleLoad = () => {
    setIsLoading(true);
    setLimit(limit + 12);
  };

  useEffect(() => {
    if (scrollY) {
      window.scrollTo(0, 0);
    }

    if (loading) {
      setLimit(7);
    }

    const fetchLeadNews = async () => {
      try {
        const response = await axiosInstance.get(
          `${process.env.REACT_APP_URL}${
            path === "allNews" ? "/allNews" : "/category"
          }`,
          { params: { number: limit, category: getBanCategory(path) } }
        );
        const dt = response.data;

        if (dt?.error) {
          navigate("/not-found");
          return;
        }

        setScrollY(false);
        setNews(dt);
        setLoading(false);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching news titles:", error);
      }
    };
    fetchLeadNews();
  }, [path, setLoading, limit, loading]); //eslint-disable-line

  const title =
    path === "allNews"
      ? "সব খবর - আজকের খবর"
      : news[0]?.category + " - আজকের খবর";

  //Loading section
  if (!news?.length || loading) {
    return (
      <div>
        {!data?.length ? (
          <>
            <div className="w-full h-[32px] skeleton-placeholder"></div>
            <div className="w-[88px] h-[25px] skeleton-placeholder"></div>
          </>
        ) : (
          ""
        )}
        <div className="w-full h-[56px] md:h-[76px] skeleton-placeholder"></div>
        <div className="md:w-[80%] mx-auto mt-5">
          <div className="md:flex gap-5">
            <div className="w-full h-[200px] md:h-[300px] mt-2 skeleton-placeholder"></div>
            <div className="w-full h-[200px] hidden md:block md:h-[300px] mt-2 skeleton-placeholder"></div>
          </div>
          <div className="w-[90%] md:w-[60%] md:hidden">
            <div className="w-full h-[40px] mt-2 skeleton-placeholder"></div>
          </div>
          <div className="my-4 md:w-[60%] mx-auto md:hidden">
            <div className="w-full h-[8px] mt-2 skeleton-placeholder"></div>
            <div className="w-full h-[8px] mt-2 skeleton-placeholder"></div>
            <div className="w-[70%] h-[8px] mt-2 skeleton-placeholder"></div>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-3 gap-5 md:mt-5">
            <div className="h-[100px] md:h-[230px] mt-2 skeleton-placeholder"></div>
            <div className="h-[100px] md:h-[230px] mt-2 skeleton-placeholder"></div>
            <div className="h-[100px] md:h-[230px] mt-2 skeleton-placeholder"></div>
            <div className="h-[100px] md:h-[230px] mt-2 skeleton-placeholder"></div>
            <div className="h-[100px] md:h-[230px] hidden md:block mt-2 skeleton-placeholder"></div>
            <div className="h-[100px] md:h-[230px] hidden md:block mt-2 skeleton-placeholder"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="min-h-[100vh]">
        <div className="mb-10">
          <div className="bg-gradient-to-r from-cyan-500 to-blue-500 ...">
            <h1 className="text-2xl md:text-4xl font-bold text-center text-white py-3 md:py-4">
              {path === "allNews" ? "সব খবর" : news[0]?.category}
            </h1>
          </div>
        </div>
        <div className="md:w-[80%] mx-auto">
          <div className="mb-10">
            <div className="mb-4">
              <div className=" md:flex gap-10 justify-center items-center">
                <div className="w-full md:w-1/2 md:mb-10">
                  {news[0]?.video ? (
                    VideoPlayer(news[0]?.video)
                  ) : (
                    <img
                      className="w-full rounded-md"
                      src={news[0]?.img}
                      alt="img"
                      loading="lazy"
                    />
                  )}
                </div>
                <div className="w-full md:w-1/2">
                  <p className="text-gray-600 text-sm flex my-4 items-center gap-2">
                    <FaClock /> {getTimeAgo(news[0]?.postTime)}
                  </p>
                  <div className="my-3 text-2xl md:text-4xl font-bold">
                    <Link
                      to={`/news/${news[0]?._id}`}
                      className="hover:text-blue-600"
                      state={news[0]}
                    >
                      {news[0]?.title}
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-8">
              {news?.slice(1)?.map((n, index) => (
                <Link to={`/news/${n?._id}`} state={n} key={index}>
                  <div>
                    <img
                      className="w-[160px] h-[92px] md:w-[282px] md:h-[170px] rounded-md"
                      src={n?.img}
                      alt="img"
                      loading="lazy"
                    />
                  </div>
                  <p className="text-gray-600 text-sm flex my-1 items-center gap-2">
                    <FaClock /> {getTimeAgo(n?.postTime)}
                  </p>
                  <p className="text-md md:text-lg font-bold my-1 hover:text-blue-600">
                    {n?.title}
                  </p>
                </Link>
              ))}
            </div>

            {isLoading ? (
              <p className="w-7 h-7 border-4 border-dashed rounded-full bg-blue-500 animate-spin mx-auto"></p>
            ) : (
              <button
                onClick={handleLoad}
                className="bg-blue-500 text-white font-bold py-2 px-6 flex m-10 mx-auto rounded-md"
              >
                আরও
              </button>
            )}
          </div>
        </div>

        <div className="w-full bg-[#222f53] p-4 rounded-md">
          <div className="flex justify-center">
            <div className="py-6">
              <p className="text-xl md:text-3xl font-bold text-white text-center">
                সর্বশেষ
              </p>
              <p className="h-[4px] bg-red-600 text-center w-1/2"></p>
            </div>
          </div>

          <SorboseshNewsSwiper />
        </div>
        <div className="md:w-[70%] mx-auto">
          <div className="flex justify-center border-b">
            <div className="mt-6">
              <p className="text-xl md:text-3xl font-bold text-center">
                সর্বাধিক পঠিত
              </p>
              <p className="h-[4px] bg-red-600 text-center w-1/2"></p>
            </div>
          </div>
          <div className="grid md:grid-cols-2 gap-8 my-8">
            {view?.slice(0, 6)?.map((d, index) => (
              <Link
                to={`/news/${d?._id}`}
                key={index}
                state={d}
                className="flex gap-4"
              >
                <p className="text-gray-500 text-md md:text-xl font-bold">
                  {convertToBengaliNumber(index + 1)}.
                </p>
                <p className="font-semibold text-md border-b md:text-xl hover:text-blue-600">
                  {d?.title}
                </p>
              </Link>
            ))}
          </div>
        </div>
        <div>
          <div className="flex justify-center">
            <div className="my-6">
              <p className="text-xl md:text-3xl font-bold text-center">
                অন্যান্য খবর
              </p>
              <p className="h-[4px] bg-red-600 text-center w-1/2"></p>
            </div>
          </div>
          <div className="md:w-[90%] mx-auto grid md:grid-cols-3 lg:grid-cols-6 gap-4 md:gap-6 mb-10">
            {data?.map((d, index) => (
              <Link
                to={`/news/${d?._id}`}
                state={d}
                key={index}
                className="border rounded-md border-gray-600 flex gap-2 md:block"
              >
                <img
                  className="w-1/2 md:h-1/2 md:w-full rounded-md"
                  src={d?.img}
                  alt="img"
                  loading="lazy"
                />
                <p className="text-md font-bold my-1 md:my-0 p-2 flex items-center hover:text-blue-600">
                  {d?.title}
                </p>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsCategory;
