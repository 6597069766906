import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { getUserInfo } from "../../helpers/auth/authHelpers";
import { Link, useNavigate } from "react-router-dom";
import axiosInstance from "./../../helpers/axios/axiosInstance";

const ConfirmationModal = ({ isOpen, onClose, setIsConfirmDelete }) => {
  if (!isOpen) return null; // Don't render the modal if it's not open

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center z-50 px-4">
      <div className="bg-white rounded-lg shadow-lg p-8 w-96">
        <h2 className="text-xl font-semibold text-gray-900 mb-4">
          Are you sure?
        </h2>
        <p className="text-gray-700 mb-6">
          Do you really want to delete this user? This action cannot be undone.
        </p>
        <div className="flex justify-end space-x-4">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-300 text-gray-800 rounded-full hover:bg-gray-400"
          >
            Cancel
          </button>
          <button
            onClick={() => setIsConfirmDelete(true)}
            className="px-4 py-2 bg-red-500 text-white rounded-full hover:bg-red-600"
          >
            Yes, Delete
          </button>
        </div>
      </div>
    </div>
  );
};

const UserCard = ({
  user,
  setIsModalOpen,
  isUpdateUser,
  setIsUpdateUser,
  setDeleteUserId,
}) => {
  const status = user?.status === "active" ? "blocked" : "active";
  const handleStatusChange = () => {
    axiosInstance
      .put(
        `${process.env.REACT_APP_URL}/update-user?userId=${user?._id}&status=${status}`
      )
      .then((data) => {
        setIsUpdateUser(!isUpdateUser);
      });
  };

  const handleDelete = () => {
    setDeleteUserId(user?._id);
    setIsModalOpen(true);
  };

  return (
    <div className="max-w-sm rounded overflow-hidden shadow-lg bg-white p-6 space-y-4">
      <div className="text-lg font-semibold text-gray-900">{user.email}</div>
      <div className="flex items-center justify-between">
        <div
          className={`px-3 py-1 text-sm font-semibold rounded-full ${
            user.status === "active"
              ? "bg-green-100 text-green-800"
              : "bg-red-100 text-red-800"
          }`}
        >
          {user.status.charAt(0).toUpperCase() + user.status.slice(1)}
        </div>
        <div className="flex gap-2 flex-col md:flex-row">
          <button
            onClick={handleStatusChange}
            className="px-4 py-1 bg-blue-500 text-white rounded-full hover:bg-blue-600 text-xs md:text-sm"
          >
            Change Status
          </button>
          <button
            onClick={handleDelete}
            className="px-4 py-1 bg-red-500 text-white rounded-full hover:bg-red-600 text-xs md:text-sm"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

const Users = () => {
  const [users, setUsers] = useState(null);
  const [isUpdateUser, setIsUpdateUser] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState("");
  const [isConfirmDelete, setIsConfirmDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const role = getUserInfo()?.role;

  if (role !== "admin") {
    navigate("/");
  }

  const cancelDelete = () => {
    setIsModalOpen(false); // Just close the modal without deleting
  };

  useEffect(() => {
    axiosInstance
      .get(`${process.env.REACT_APP_URL}/all-users`)
      .then((data) => {
        setUsers(data?.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error("Something went wrong!");
      });
  }, [isUpdateUser]);

  useEffect(() => {
    if (isConfirmDelete) {
      axiosInstance
        .delete(`${process.env.REACT_APP_URL}/delete-user/${deleteUserId}`)
        .then((data) => {
          if (data?.data) {
            setDeleteUserId("");
            setIsConfirmDelete(false);
            setIsModalOpen(false);
            toast.success("User deleted successfully!");
            setIsUpdateUser(!isUpdateUser);
          }
        })
        .catch((err) => {
          console.log(err);
          setDeleteUserId("");
          setIsConfirmDelete(false);
          setIsModalOpen(false);
          toast.error("Something went wrong!");
        });
    }
  }, [isConfirmDelete, deleteUserId, isUpdateUser]);

  console.log(isLoading, users?.length);
  return (
    <div className="min-h-screen bg-gray-100 mt-5">
      <Toaster />
      <div className="flex justify-center pt-5">
        <div className="container max-w-lg mx-auto bg-white p-8 rounded-lg shadow-lg">
          <div className="text-center mb-6 mt-10 md:mt-0">
            <h1 className="text-3xl font-semibold text-gray-800">All Users</h1>
          </div>
          <div className="mb-2 flex justify-end">
            <Link
              to="/dashboard/create-user"
              className="px-4 py-1 rounded-md bg-green-500 text-white text-xs md:text-sm"
            >
              Create User
            </Link>
          </div>
          {users?.length ? (
            users.map((user, i) => (
              <UserCard
                key={i}
                user={user}
                setIsModalOpen={setIsModalOpen}
                setIsUpdateUser={setIsUpdateUser}
                isUpdateUser={isUpdateUser}
                setDeleteUserId={setDeleteUserId}
              />
            ))
          ) : (
            <div className="text-center text-base my-5 ">
              {!isLoading && users?.length === 0 ? (
                <p>No user found!</p>
              ) : (
                <p>Loading...</p>
              )}
            </div>
          )}
        </div>

        <ConfirmationModal
          isOpen={isModalOpen}
          onClose={cancelDelete}
          setIsConfirmDelete={setIsConfirmDelete}
        />
      </div>
    </div>
  );
};

export default Users;
