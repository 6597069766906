import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { getUserInfo } from "../helpers/auth/authHelpers";

const PrivateRoutes = ({ children }) => {
  const user = getUserInfo();

  const location = useLocation();

  if (user && user?.email) {
    return children;
  }

  return <Navigate to="/login" state={{ from: location }} replace />;
};

export default PrivateRoutes;
