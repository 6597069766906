import axios from "axios";
import { logout } from "../auth/authHelpers";

const API_BASE_URL = process.env.REACT_APP_URL;

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Add request interceptor to add token if available
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add response interceptor to handle token expiration
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  }, // If request is successful, just return the response
  async (error) => {
    const originalRequest = error.config;

    if (
      error.response &&
      error.response?.data?.message?.includes("Token expired") &&
      !originalRequest._retry
    ) {
      // Try to refresh the token
      try {
        const getToken = localStorage.getItem("accessToken"); // Get the refresh token
        if (!getToken) throw new Error("No access token available");

        fetch(`${API_BASE_URL}/refresh-token`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        })
          .then((res) => res.json())
          .then((data) => {
            const accessToken = data?.accessToken;
            if (data?.message === "User not found!") {
              logout();
              return;
            }
            if (data?.message === "Refresh token invalid!") {
              logout();
              return;
            }
            if (accessToken) {
              // Save the new access and refresh token
              localStorage.setItem("accessToken", accessToken);
              // Update the original request's authorization header with the new access token
              originalRequest.headers[
                "Authorization"
              ] = `Bearer ${accessToken}`;
              // Prevent infinite loop of retries
              originalRequest._retry = true;
            }
          })
          .catch((error) => {
            // console.log(error);
            console.error("Failed to refresh token:", error);
          });

        return axiosInstance(originalRequest);
        // Retry the original request with the new token
      } catch (refreshError) {
        // Handle token refresh errors, like redirecting to login page
        // console.error("Token refresh failed:", refreshError);
        // Optionally, you can redirect the user to the login page
        // window.location.href = "/login"; // Redirect to login
        return Promise.reject(refreshError);
      }
    }

    // If not a 401 error or token refresh failed, reject the error
    return Promise.reject(error);
  }
);

export default axiosInstance;
